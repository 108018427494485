import React, { useState, useEffect, useRef } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";
import Decrypt from "../pages/Decrypt";
import Dec2bin from "../pages/Dec2bin";

import Sidebar from "../Sidebar";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  //TODO:
  const [Loading, setLoading] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  //TODO:
  // Function to filter transactions for the selected date
  const fetchTransactionsForSelectedDate = () => {
    setLoading(true);

    // Simulated delay to show the loading spinner
    setTimeout(() => {
      // Filter transactions based on the selected date
      const filteredTransactions = day_transaction.filter(
        (transaction) => transaction.date === selectedDate // Replace 'date' with your date property
      );

      setSelectedTransactions(filteredTransactions);
      setLoading(false);
    }, 8000); // Simulated delay - replace this with your actual data fetching logic
  };

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };
  const [ison, setIson] = useState(false);

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  let imeilist = JSON.parse(localStorage.getItem("imeilist"));

  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  //TODO:
  const getTransactionsForDate = (selectedDate) => {
    //  'transactionID' contains all the transactions
    const transactionsForDate = transactionID.filter((transaction) => {
      // 'date' is the field in your transaction data that holds the date
      return transaction.date === selectedDate;
    });

    return transactionsForDate;
  };

  // TODO: selected date event handle

  const handleChange = (event) => {
    const confirmation = window.confirm(`ARE YOU SURE TO CHANGE THE DATE?`);
    //
    if (confirmation) {
      const selectedDate = event.target.value;
      setSelectedDate(selectedDate);
      // setSelectedDate(event.target.value);
    }
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);

    // Fetch transactions for the selected date here
    const transactionsForSelectedDate = getTransactionsForDate(selectedDate);
    setDayTransaction(transactionsForSelectedDate);
  };

  // TODO:

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    // device_id: 226,
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data, headers);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [powerArr, setPowerArr] = useState([]);
  let [vAvg, setVavg] = useState([]);
  let [iAvg, setIAvg] = useState([]);
  let [EnergyArr, setEnergyArr] = useState([]);
  let [avgPfArr, setAvgPfArr] = useState([]);
  let [alertArr, setAlertArr] = useState([]);
  let [alertTime, setAlertTime] = useState([]);

  //transaction id and other data -
  let [tid, setTid] = useState([]);
  let [mid, setMid] = useState([]);
  let [ddate, setDdate] = useState([]);
  let [dtime, setDtime] = useState([]);
  let [price, setPrice] = useState([]);
  let [amt, setAmt] = useState([]);
  let [litre, setLitre] = useState([]);
  let [vid, setVid] = useState([]);
  let [totalizer, setTotalizer] = useState([]);
  let [status, setStatus] = useState([]);
  let [operation, setOperation] = useState([]);
  let [totalT, setTotalT] = useState([]);

  let [transactionID, setTranscationId] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  let currentime = convertTo24Hour(
    JSON.parse(localStorage.getItem("currentime"))
  );

  useEffect(() => {
    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        // "http://13.234.254.166/dwlr_api/api/Dwlr_dt_payload_details/Dt_details",
        data,
        { headers }
      )
      .then(function (response) {
        let x = response.data.data;
        let log = x.logs;

        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          let y = log[i].log_time;

          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));

          tid[i] =
            Number(message[i][0]) * (256 * 256 * 256) +
            Number(message[i][1]) * (256 * 256) +
            Number(message[i][2]) * 256 +
            Number(message[i][3]) * 1;

          mid[i] =
            Number(message[i][4]) * (256 * 256 * 256) +
            Number(message[i][5]) * (256 * 256) +
            Number(message[i][6]) * 256 +
            Number(message[i][7]) * 1;

          dtime[i] =
            message[i][12] +
            ":" +
            message[i][14] +
            ":" +
            message[i][15] +
            " " +
            (message[i][13] === 1 ? "PM" : "AM");

          ddate[i] =
            message[i][8] +
            "-" +
            message[i][9] +
            "-" +
            (Number(message[i][10]) * 256 + Number(message[i][11]));
          //litre, price and amount were decrypted beacues they were MSRF
          litre[i] = Decrypt(
            Dec2bin(
              (Number(message[i][16]) * 256 + Number(message[i][17])) * 65536 +
                (Number(message[i][18]) * 256 + Number(message[i][19]))
            )
          );

          price[i] = Decrypt(
            Dec2bin(
              (Number(message[i][20]) * 256 + Number(message[i][21])) * 65536 +
                (Number(message[i][22]) * 256 + Number(message[i][23]))
            )
          );

          amt[i] = Decrypt(
            Dec2bin(
              (Number(message[i][24]) * 256 + Number(message[i][25])) * 65536 +
                (Number(message[i][26]) * 256 + Number(message[i][27]))
            )
          );

          vid[i] = Number(message[i][30]) * 256 + Number(message[i][31]) * 1;

          totalizer[i] = Math.floor(
            Decrypt(
              Dec2bin(
                (Number(message[i][32]) * 256 + Number(message[i][33])) *
                  65536 +
                  (Number(message[i][34]) * 256 + Number(message[i][35]))
              )
            )
          );

          status[i] = message[i][40];
          operation[i] = message[i][41];

          totalT[i] =
            Number(message[i][36]) * (256 * 256 * 256) +
            Number(message[i][37]) * (256 * 256) +
            Number(message[i][38]) * 256 +
            Number(message[i][39]) * 1;

          transactionID[i] = [
            tid[i],
            mid[i],
            dtime[i],
            ddate[i],
            price[i],
            litre[i],
            amt[i],
            vid[i],
          ];
          //end

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 50; index++) {
            dataArr[i] = msgArray;
            // console.log(dataArr[i]);
          }
        }
        setTimeArray(timeArray);
        setTid(tid);
        setMid(mid);

        for (let i = 0; i < dataArr.length; i++) {
          avgPfArr[i] = dataArr[i][30] / 100;
          powerArr[i] = dataArr[i][14];
          vAvg[i] = dataArr[i][3];
          iAvg[i] = dataArr[i][11];
          EnergyArr[i] = dataArr[i][12] * 65536 + dataArr[i][13];
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    fetchTransactionsForSelectedDate();
  }, [selectedDate, panelinfo.deviceid]);

  let amount_total = [];
  let batch_mode = [];
  let delivered_volumn = [];
  let device_trans_date = [];
  let device_trans_time = [];
  let imei_no = [];
  let machine_id = [];
  let no_of_transaction = [];
  let price_per_ltr = [];
  let status_number = [];
  let transid = [];
  let total_dispence_value = [];
  let vehicle_id = [];

  let [day_transaction, setDayTransaction] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    // setTimeout(() => {
    axios
      .get(
        `http://13.234.254.166/dwlr_api/api/Dwlr_dt_payload_details/Dt_payload_details?imei_no=${
          imeilist[panelinfo.cardid]
        }&device_trans_date=${selectedDate}`
      )
      .then(function (response) {
        console.log(response);
        // console.log(response.data.data);
        let resarr = response.data.data;
        console.log(resarr);
        amount_total = [];
        batch_mode = [];
        delivered_volumn = [];
        device_trans_date = [];
        device_trans_time = [];
        imei_no = [];
        machine_id = [];
        no_of_transaction = [];
        price_per_ltr = [];
        status_number = [];
        transid = [];
        total_dispence_value = [];
        vehicle_id = [];
        for (let i = 0; i <= resarr.length; i++) {
          // console.log(resarr[i].amount_total);
          amount_total.push(resarr[i].amount_total);
          batch_mode.push(resarr[i].batch_mode);
          delivered_volumn.push(resarr[i].delivered_volumn);
          device_trans_date.push(resarr[i].device_trans_date);
          device_trans_time.push(resarr[i].device_trans_time);
          imei_no.push(resarr[i].imei_no);
          machine_id.push(resarr[i].machine_id);
          no_of_transaction.push(resarr[i].no_of_transaction);
          price_per_ltr.push(resarr[i].price_per_ltr);
          status_number.push(resarr[i].status_number);
          transid.push(resarr[i].tid);
          total_dispence_value.push(resarr[i].total_dispence_value);
          vehicle_id.push(resarr[i].vehicle_id);

          day_transaction[i] = [
            i + 1,
            transid[i],
            machine_id[i],
            vehicle_id[i],
            device_trans_time[i],
            device_trans_date[i],
            price_per_ltr[i],
            delivered_volumn[i],
            amount_total[i],
            batch_mode[i],

            // imei_no[i],

            // no_of_transaction[i],

            // status_number[i],

            // total_dispence_value[i],
          ];
          setLoading(false);
        }
        setDayTransaction(day_transaction);
        setLoad(false);
      })
      .catch(function (err) {
        console.log(err);
      });
    // }, 5000);
    // fetchTransactionsForSelectedDate();
  }, [selectedDate, panelinfo.deviceid]);

  console.log(day_transaction);

  // TODO: calculation for missing tansaction- START
  let transacArr = [];
  for (let i = 0; i < day_transaction.length; i++) {
    transacArr.push(day_transaction[i][1]);
  }
  console.log(transacArr);

  // function findMissingNumber(arr) {
  //   const min = Math.min(...arr);
  //   const max = Math.max(...arr);

  //   for (let i = min; i <= max; i++) {
  //     if (!arr.includes(i)) {
  //       return i;
  //     }
  //   }

  //   return null;
  // }
  // const missing = findMissingNumber(transacArr);
  // console.log("The missing Transaction ID is:", missing);

  // TODO: calculation for missing tansaction - END

  const uniqueTransactions = [];
  const seenArrays = new Set();

  for (const array of transactionID) {
    if (!array.includes("0-0-0")) {
      const arrayString = JSON.stringify(array);
      if (!seenArrays.has(arrayString)) {
        seenArrays.add(arrayString);
        uniqueTransactions.push(array);
      }
    }
  }

  let timeHour = [];
  let energyPerHour = [];

  for (let i = 0; i < EnergyArr.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, EnergyArr.length - 1);
    energyPerHour.push(EnergyArr[end] - EnergyArr[start]);
    timeHour.push(timeArray[end]);
  }

  const cards = [];
  const options = [];
  for (let i = 0; i < day_transaction.length; i++) {
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });
    cards.push(
      <div
        key={i}
        id={`card${i}`}
        className=" w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6  mt-2  ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 
    max-w-sm max-h-full  overflow-hidden  m-2 cursor-pointer flex-grow bg-blue-900   rounded-lg shadow-md p-6  text-white"
      >
        <h3 className="text-lg font-semibold mb-4 text-center bg-red-500 text-black py-2 rounded-lg tracking-wider uppercase font-mono">
          Transaction ID: <span>{day_transaction?.[i]?.[1]}</span>
        </h3>
        <table className="text-gray-100">
          <tbody>
            {/* <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Transaction ID:
              </td>
              <td className="font-medium text-yellow-400">
                {uniqueTransactions?.[i]?.[0]}
              </td>
            </tr> */}

            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Machine ID:
              </td>
              <td className="font-medium text-yellow-400">
                {day_transaction?.[i]?.[2]}
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Transaction Date:
              </td>
              <td className="font-medium text-yellow-400">
                {day_transaction?.[i]?.[5]}
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Transaction Time:
              </td>
              <td className="font-medium text-yellow-400">
                {day_transaction?.[i]?.[4]}
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Price per Litre:
              </td>
              <td className="font-medium text-yellow-400">
                ₹{day_transaction?.[i]?.[6]}
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Fuel Withdrawn:
              </td>
              <td className="font-medium text-yellow-400">
                {day_transaction?.[i]?.[7]} L
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Total Amount:
              </td>
              <td className="font-medium text-yellow-400">
                ₹{day_transaction?.[i]?.[8]}
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Vehicle ID:
              </td>
              <td className="font-medium text-yellow-400">
                {day_transaction?.[i]?.[3]}
              </td>
            </tr>
            <tr>
              <td className="text-gray-200 pr-4 font-semibold tracking-wider uppercase">
                Batch Mode:
              </td>
              <td className="font-medium text-yellow-400">
                {day_transaction?.[i]?.[9]}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const sortedCards = [...cards].sort((a, b) => {
    const idA = parseInt(day_transaction[a.key]?.[1]);
    const idB = parseInt(day_transaction[b.key]?.[1]);

    return idA - idB;
  });

  function exportToCsv(data, columns, filename) {
    const csvData = [columns.join(",")]
      .concat(data.map((row) => row.join(",")))
      .join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const columns = [
    "Sr. No",
    "Transaction ID",
    "Machine ID",
    "Vehicle ID",
    "TimeStamp",
    "Date",
    "Price per Litre",
    "Fuel Withdrawn",
    "Total Amount",
    "Batch Mode",
  ];

  const handleExportToCsv = () => {
    exportToCsv(day_transaction, columns, "transaction_data.csv");
  };

  const [withdrawnL, setwithdrawnL] = useState(0);

  const handleWithdrawnL = (e) => {
    setwithdrawnL(e.target.value);
  };

  let paddedvalue = withdrawnL;

  let withdrawCommand = {
    type: "command",
    id: 1,
    cmd: "mod_cmd",
    value: `14100001000408014500C800320001`,
  };

  let NaNfreeTotalizer = totalizer.filter((value) => !isNaN(value));

  let prunedTotalTransaction = totalT.filter(
    (value) => value !== 0 && value <= 10000
  );

  // console.log(mqttdata?.[0]);
  // TODO: HEX CONVERSION
  let hexvalue;

  function check(val) {
    if (val >= "a" && val <= "f") {
      return val.toUpperCase();
    }
    return val;
  }
  let [missingMessage, setMissingMessage] = useState({});
  function vijay(fdata) {
    let a = Math.floor(fdata / 16);
    let b = Math.floor(a / 16);
    let c = Math.floor(b / 16);
    let d = check((fdata % 16).toString(16));
    let e = check((a % 16).toString(16));
    let f = check((b % 16).toString(16));
    let g = check((c % 16).toString(16));

    console.log(fdata);
    // console.log(a,b,c,d,e,f,g);
    // console.log(g, f, e, d);
    hexvalue = g.concat(f, e, d);
    // console.log(hexvalue);

    missingMessage = {
      type: "command",
      id: 1,
      cmd: "mod_cmd",
      value: `190C04019202${hexvalue}`,
    };
    setMissingMessage(missingMessage);
    console.log(missingMessage);
  }
  // TODO: HEX CONVERSION
  // console.log(hexvalue);
  // vijay(30);
  const handleChangeMissingVal = (e) => {
    const value = e.target.value;
    // setvl2under(value);
    vijay(value);
  };

  // let missingMessage = {
  //   type: "command",
  //   id: 1,
  //   cmd: "mod_cmd",
  //   value: `190C04019202${hexvalue}`,
  // };
  console.log(missingMessage);
  //TODO:level
  console.log(mqttdata[panelinfo.cardid][525]);
  // let level = mqttdata[panelinfo.cardid][525];

  let level = (mqttdata[panelinfo.cardid][525] * 0.0729).toFixed(2);

  return (
    <div className=" bg-gray-200 relative min-h-screen ">
      {/* //nav bar- part1 -logo and title */}
      <div className=" flex flex-wrap font-sans font-semibold justify-between align-middle items-center py-0.5 gap-2 sticky top-0 z-50 border-b-2 border-blue-600 bg-blue-300">
        <div className="flex flex-col flex-wrap justify-start">
          <div>
            <img src="./images/logo2.png" alt="logo" className="pl-1 h-8"></img>
          </div>
          <div className="flex flex-col text-sm pl-1 text-black">
            <h2>FUEL MONITORING SYSTEM</h2>
            {/* <h2>AUTOMATION</h2> */}
          </div>
        </div>
        {/* nav bar- part 2 - date selector */}
        <div className="flex flex-wrap gap-2 justify-center mt-1">
          <div className="flex items-center mb-2 hover:bg-gray-100 rounded-lg p-1">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <div className="relative">
              <input
                type="date"
                id="datepicker"
                value={selectedDate}
                onChange={handleChange}
                className="px-3 py-2 border rounded-md w-36 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none pr-2 text-gray-500">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 12h14M12 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* nav bar-part3  */}
          <div className="flex align-middle justify-start mb-2 items-center hover:bg-light-white rounded-md p-1">
            <UserMenu />
          </div>
          {/* nav bar- part 4 */}
          {/* //* New input box and send button */}
          <div className="flex items-center">
            <input
              type="number"
              onChange={handleChangeMissingVal}
              placeholder="Enter Missing TID"
              className="border rounded-l-lg py-1 px-2 focus:outline-none"
            />
            <button
              onClick={() => sendmsg(panelinfo.imei, missingMessage)}
              className="bg-blue-500 text-white hover:bg-blue-700 rounded-r-lg py-1 px-3 ml-1"
            >
              Send
            </button>
          </div>
          {/* End of new input box and send button */}
        </div>
      </div>

      <main className="p-0 m-0 min-h-screen ">
        <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
          <div className="text-center">
            {Loading ? (
              <div className="text-2xl font-bold text-center text-blue-700 mt-8">
                Loading...
              </div>
            ) : (
              <div>
                {day_transaction.length > 0 ? (
                  day_transaction.map((transaction, index) => (
                    <div key={index}>
                      {/* {sortedCards} */}
                      {/* Display transaction data here */}
                      {/* The table structure for displaying each transaction */}
                    </div>
                  ))
                ) : (
                  <div className="text-xl font-bold text-center text-red-500 mt-8 ">
                    No Transactions Available for the Selected Date.
                  </div>
                )}
              </div>
            )}
          </div>

          <div class="max-w-md mx-auto rounded-lg overflow-hidden shadow-lg my-8 bg-gradient-to-br from-green-300 to-green-500">
            <div class="flex items-center justify-center h-11">
              <h2 class="text-white text-2xl font-bold">Fuel Level</h2>
            </div>
            <div class="px-6 py-4">
              <div class="text-center">
                <span class="text-3xl text-white font-bold">{level}%</span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap min-w-fit">{sortedCards}</div>

          <div className="bg-white bg-opacity-50  rounded-lg shadow-md p-6 mx-auto my-8">
            <div className="bg-gray-200 rounded-lg shadow-md  p-6 mx-auto my-8 text-white">
              <h3 className="text-lg font-semibold mb-4 text-center  text-black py-2 rounded-lg tracking-wider uppercase font-mono">
                Transaction Details (REPORTS)
              </h3>

              <div className="overflow-auto">
                <div className="min-w-full overflow-auto ">
                  <table className="min-w-full text-black border border-collapse border-black">
                    <colgroup>
                      <col className="w-1/12" />
                      <col className="w-1/12" />
                      <col className="w-2/12" />
                      <col className="w-2/12" />
                      <col className="w-1/12" />
                      <col className="w-2/12" />
                      <col className="w-2/12" />
                      <col className="w-1/12" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Sr. No
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Transaction ID
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Machine ID
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Vehicle ID
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Timestamp
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Date
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Price per Litre
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Fuel Withdrawn
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Total Amount
                        </th>
                        <th className="text-left py-2 px-2 md:px-4 border bg-red-600 text-white border-black">
                          Batch
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {day_transaction.length > 0 &&
                        day_transaction.map((transaction, index) => (
                          <tr key={index}>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              {index + 1}.
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              {transaction[1]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              {transaction[2]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black bg-white text-bold">
                              {transaction[3]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              {transaction[4]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              {transaction[5]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              ₹ {transaction[6]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black  bg-white text-bold">
                              {transaction[7]} L
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black bg-white text-bold">
                              ₹ {transaction[8]}
                            </td>
                            <td className="py-2 px-2 md:px-4 border border-black bg-white text-bold">
                              {transaction[9]}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="text-center mt-2">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-3 "
                  onClick={handleExportToCsv}
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* </div> */}
    </div>
  );
}

export default Dashboard;
